<template>
    <div class="spinner">
        <AtomIcon name="spinner" :width="null" />
        <slot name="spinnerContext"/>
    </div>
</template>

<style lang="scss" scoped>
.spinner {
    width: 100%;
    height: rem(200);
    flex-direction: column;

    @include helper-color(state-default);
    @include helper-flex-centered();

    .error {
        @include helper-color(alert-danger);
    }

    .svg-icon {
        height: 80%;
        max-width: rem(200);
        max-height: rem(200);
    }
}
</style>
